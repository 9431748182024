import { useTranslations } from 'next-intl'
import { IconHome } from 'components/icons/components/IconHome'
import { IconUserOutline } from 'components/icons/components/IconUserOutline'
import { IconCommunity } from 'components/icons/components/IconCommunity'
import { ROUTES } from 'constants/routes'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Text } from 'components/ui/Text'
import { useAuthContext } from 'context/auth'
import includes from 'utils/includes'
import { IconProducts } from 'components/icons/components/IconProducts'
import { IconFiles } from 'components/icons/components/IconFiles'
import { useState } from 'react'

const IconOrder = ({ className }: { className: string }) => {
  // TODO: placeholder for displaying notification on orders, ideally from a context
  const [hasNotification] = useState(false)

  return (
    <>
      {hasNotification && (
        <span
          className="absolute rounded-full h-[6px] aspect-square top-3 right-6 ring-2 ring-white"
          style={{
            backgroundImage:
              'linear-gradient(92.63deg, #FF7D74 0%, #F04438 100%)',
          }}
        />
      )}
      <IconFiles className={className} />
    </>
  )
}

export function NavigationBar() {
  const { pathname } = useRouter()
  const t = useTranslations()
  const { isLoading } = useAuthContext()

  if (isLoading) {
    return null
  }

  const MENU_ITEMS = [
    { path: ROUTES.HOME, label: t('home'), Icon: IconHome },
    { path: ROUTES.PRODUCTS, label: t('products'), Icon: IconProducts },
    { path: ROUTES.ORDERS(), label: t('orders'), Icon: IconOrder },
    { path: ROUTES.CUSTOMERS, label: t('customers'), Icon: IconCommunity },
    { path: ROUTES.PROFILE, label: t('profile'), Icon: IconUserOutline },
  ]

  return (
    <nav
      id="main-nav"
      // TODO: extract this to a tailwind class
      style={{
        boxShadow:
          '0 -0.25rem 1rem rgb(0 0 0 / 0.05), 0 -1rem 1rem rgb(0 0 0 / 0.02)',
      }}
      className="fixed bottom-0 inset-x-0 z-20 text-v2-2xs leading-4 sm:container bg-white border border-top border-gray-400 flex justify-center"
    >
      {MENU_ITEMS.map(({ Icon, label, path }) => {
        const isActive =
          (includes([ROUTES.RANK, ROUTES.HOME, ROUTES.PROMOTIONS], pathname) &&
            path === ROUTES.HOME) ||
          (path !== ROUTES.HOME && pathname.startsWith(path))
        return (
          <div
            key={label}
            className="w-1/5 justify-center flex whitespace-nowrap font-bold"
          >
            <Link href={path}>
              <a
                className={`relative flex flex-col items-center justify-center w-[75px] h-[58px] transition-colors ${
                  isActive ? 'text-v2-primary-400' : 'text-gray-700'
                }`}
              >
                <Icon className="w-6 h-6 flex justify-center items-center" />
                <Text textColor="none" as="span">
                  {label}
                </Text>
              </a>
            </Link>
          </div>
        )
      })}
    </nav>
  )
}
