import { isSSR } from '@dwarvesf/react-utils'

export const getIOSVersion = () => {
  if (isSSR()) {
    return undefined
  }

  const userAgentInfo = window.navigator.userAgent

  let res: number | undefined

  // if current device is working on iOS, return the version as number,
  // otherwise return undefined
  if (userAgentInfo.includes('iPhone')) {
    const versionInfo: RegExpMatchArray | null = userAgentInfo.match(
      /iPhone OS ([0-9]+([_][0-9]*)?)/gi, // since the userAgent for iPhone shows 'iPhone OS 15_5'
    )

    if (versionInfo !== null) {
      res = parseFloat(versionInfo[0].replace('_', '.').split(' ')[2])
    }
  }

  return res
}

export function isMobile(): boolean {
  if (isSSR()) {
    return false
  }
  const { userAgent } = navigator
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent,
  )
}
