import { IMAGE_PREFIX } from 'constants/common'

export function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

interface RemoveAccentsParams {
  str: string
  ignoreLowerCase?: boolean
}
export function removeAccents({
  str,
  ignoreLowerCase = false,
}: RemoveAccentsParams) {
  let returnStr = str
  // remove accents
  const from =
    'àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçỳýỵỹỷÀÁÃẢẠĂẰẮẲẴẶÂẦẤẨẪẬÈÉẺẼẸÊỀẾỂỄỆĐÙÚỦŨỤƯỪỨỬỮỰÒÓỎÕỌÔỒỐỔỖỘƠỜỚỞỠỢÌÍỈĨỊÄËÏÎÖÜÛÑÇỲÝỴỸỶ'

  const to =
    'aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyyAAAAAAAAAAAAAAAAAEEEEEEEEEEEDUUUUUUUUUUUOOOOOOOOOOOOOOOOOIIIIIAEIIOUUNCYYYYY'

  for (let i = 0, l = from.length; i < l; i++) {
    returnStr = returnStr.replace(from[i], to[i])
  }

  returnStr = ignoreLowerCase
    ? returnStr.trim()
    : returnStr.toLowerCase().trim()

  return returnStr
}

export const encodeImage = (streamId?: string) => {
  return streamId ? encodeURIComponent(encodeURIComponent(streamId)) : ''
}

export function stringToNumber(str: string, fallback: number = 0) {
  const converted = str === '' ? fallback : Number(str)
  // Remove all chars that not a number
  const result = Number.isNaN(converted) ? str.replace(/\D/g, '') : converted
  const number = result === '' ? fallback : Number(result)
  if (Number.isNaN(number)) {
    return fallback
  }

  return number
}

export const formatImage = (streamId?: string) => {
  return streamId ? `${IMAGE_PREFIX}/${encodeImage(streamId)}` : ''
}

export function randomItem<T>(items: T[]) {
  const index = Math.floor(Math.random() * items.length)
  return items[index]
}

export function generateQRCodePayment(
  bankId?: number,
  accountNumber?: string,
  amount?: string | number,
  description?: string,
  accountHolder?: string,
) {
  return `https://img.vietqr.io/image/${bankId}-${accountNumber}-qr_only.png?amount=${amount}&addInfo=${encodeURI(
    `${description}`,
  )}&accountName=${encodeURI(accountHolder || '')}`
}

export function shortenUsername(name: string): string {
  const trimmedName = name.trim()
  const nameArr = trimmedName.split(' ')
  let ret: string = ''

  const filteredNameArr = nameArr.filter((element) => {
    return element !== ''
  })

  filteredNameArr.forEach((n: string, i: number) => {
    if (i === filteredNameArr.length - 1) {
      ret += n
    } else {
      ret += `${n[0]}. `
    }
  })
  return ret
}

export function parseJWT(token: string) {
  const base64Url = token.split('.')[1]
  // Not a valid jwt
  if (!base64Url) return null

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2) // eslint-disable-line prefer-template
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

export function replaceLeadingChars(str: string): string {
  return `******${str.substring(6)}`
}
