import hotToast, { Toaster as HotToaster, ToastPosition } from 'react-hot-toast'
import cx from 'classnames'
import {
  ToastAlert,
  ToastAlertBody,
  ToastAlertContent,
  ToastAlertIcon,
  ToastAlertProps,
} from './ToastAlert'
import { Text } from '../Text'

import type { ToastVariant } from './Toast.types'

const DEFAULT_DURATION = 5000 // 5 seconds

interface ToastProps {
  message: React.ReactNode
  title?: React.ReactNode
  className?: string
  contentClassName?: string
  onClose?: () => void
  renderAction?: ({ onClose }: { onClose: () => void }) => React.ReactNode
  scheme?: ToastAlertProps['scheme']
  variant?: ToastVariant
  action?: React.ReactNode
  duration?: number
  position?: ToastPosition
  isClosable?: boolean
  isIconVisible?: boolean
  fullWidth?: boolean
  marginBottom?: number
  extra?: React.ReactNode
}

function getBottomThreshold() {
  const nodes = document.querySelectorAll('footer.fixed.bottom-0, nav#main-nav')

  return Math.max(
    0,
    ...Array.from(nodes).map((node) => {
      return node.getBoundingClientRect().height
    }),
  )
}

const calculateAutoClose = (message: string) => {
  // Calculate based on text content
  return message.length * 100
}

const MarginInjector = ({ marginBottom }: { marginBottom: number }) => {
  const styles = `
    .react-hot-toast > div { margin-bottom: ${marginBottom}px!important };
  `
  return <style>{styles}</style>
}

export function toast(props: ToastProps) {
  const {
    onClose,
    message,
    duration = typeof message === 'string'
      ? calculateAutoClose(message)
      : DEFAULT_DURATION,
    position = 'bottom-center',
    scheme = 'error',
    variant = 'solid',
    isClosable = true,
    isIconVisible = true,
    renderAction,
    className,
    contentClassName,
    title,
    fullWidth,
    marginBottom = getBottomThreshold(),
    extra,
    ...rest
  } = props

  hotToast(
    (t) => {
      const closeToast = () => {
        hotToast.dismiss(t.id)
        if (onClose) {
          onClose()
        }
      }

      return (
        <>
          <MarginInjector marginBottom={marginBottom} />
          <ToastAlert
            isClosable={isClosable}
            onClose={closeToast}
            variant={variant}
            scheme={scheme}
            renderAction={renderAction}
            id={t.id}
            fullWidth={fullWidth}
            className={className}
            {...rest}
          >
            {isIconVisible && <ToastAlertIcon />}

            <ToastAlertBody>
              <ToastAlertContent className={contentClassName}>
                {title ? (
                  <Text className="text-[inherit] font-bold">{title}</Text>
                ) : null}
                {message}
                {extra}
              </ToastAlertContent>
            </ToastAlertBody>
          </ToastAlert>
        </>
      )
    },
    { duration, position },
  )
}

toast.error = (props: Omit<ToastProps, 'scheme'>) => {
  toast({ scheme: 'error', ...props })
}

toast.success = (props: Omit<ToastProps, 'scheme'>) => {
  toast({ scheme: 'success', ...props })
}

toast.warn = (props: Omit<ToastProps, 'scheme'>) => {
  toast({ scheme: 'warning', ...props })
}

toast.info = (props: Omit<ToastProps, 'scheme'>) => {
  toast({ scheme: 'info', ...props })
}

toast.primary = (props: Omit<ToastProps, 'scheme'>) => {
  toast({ scheme: 'primary', ...props })
}

toast.simple = (props: ToastProps) => {
  const { scheme = 'gray', className } = props
  toast({
    scheme,
    isClosable: false,
    isIconVisible: false,
    fullWidth: false,
    className: cx('text-center !py-4 !px-6 justify-center', className),
    ...props,
  })
}

export const Toaster = () => (
  <HotToaster
    containerClassName="react-hot-toast"
    toastOptions={{
      style: {
        border: 'none',
        padding: '0',
        color: 'transparent',
        background: 'transparent',
        boxShadow: 'none',
        borderRadius: 0,
        lineHeight: 'auto',
        margin: '0',
        maxWidth: 'auto',
        textAlign: 'center',
      },
    }}
  />
)
