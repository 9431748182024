import React from 'react'
import cx from 'classnames'
import { IconSpinner } from 'components/icons/components/IconSpinner'
import { forwardRefWithAs } from 'utils/render'
import { WithChildren } from 'types/common'
import { FontSize, getFontSize } from 'utils/typography'
import { ButtonScheme, ButtonSize, ButtonVariant } from '../BaseButton/types'
import { getAppearanceButtonStyles } from '../BaseButton/utils'
import { BaseButton, BaseButtonProps } from '../BaseButton'

export interface ButtonProps extends WithChildren<BaseButtonProps> {
  variant?: ButtonVariant
  scheme?: ButtonScheme
  fullWidth?: boolean
  iconPosition?: 'left' | 'right'
  loading?: boolean
  disabled?: boolean
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  iconClassName?: string
  asLabel?: boolean
  isRounded?: boolean
  isNoSpacing?: boolean
}

function getDefaultSize(size: ButtonSize): FontSize {
  if (size === 'xl') {
    return 'lg'
  }

  if (size === 'lg') {
    return 'nm'
  }

  if (size === 'md') {
    return 'nm'
  }

  if (size === 'sm') {
    return 'xs'
  }

  return '2xs'
}

function getButtonStyles({
  size = 'md',
  fullWidth = false,
  loading,
  asLabel,
  isRounded,
  isNoSpacing,
}: Omit<ButtonProps, 'children'>) {
  const classNames = ['relative']
  classNames.push(getFontSize(getDefaultSize(size)))

  if (fullWidth) {
    classNames.push('w-full flex')
  }

  if (loading || asLabel) {
    classNames.push('cursor-default')
  }

  if (isRounded) {
    classNames.push('rounded-full')
  } else {
    classNames.push('rounded-lg')
  }

  if (!isNoSpacing) {
    classNames.push('px-3')
    if (size === 'xl') {
      classNames.push('h-14')
    }

    if (size === 'lg') {
      classNames.push('h-12')
    }

    if (size === 'md') {
      classNames.push('h-10')
    }

    if (size === 'sm') {
      classNames.push('h-8')
    }

    if (size === 'xs') {
      classNames.push('h-7')
    }
  }

  return classNames
}

function getIconSize(size: ButtonSize) {
  if (size === 'xl') {
    return 'text-v2-2xl'
  }

  if (size === 'lg') {
    return 'text-v2-xl'
  }

  if (size === 'md') {
    return 'text-v2-lg'
  }

  if (size === 'sm') {
    return 'text-v2-md'
  }

  return 'text-v2-nm'
}

export const Button = forwardRefWithAs<'button', ButtonProps>(
  (
    {
      size = 'md',
      Icon,
      iconPosition = 'left',
      iconClassName,
      children: originChildren,
      loading = false,
      asLabel = loading,
      className,
      fullWidth = false,
      isRounded = false,
      isNoSpacing = false,
      ...props
    },
    ref,
  ) => {
    let children = originChildren
    const fontSize = getDefaultSize(size)

    if (loading) {
      Icon = IconSpinner
    }

    if (Icon) {
      const iconSize = getIconSize(size)
      children = (
        <>
          {iconPosition === 'left' && (
            <Icon className={cx('mr-2', iconSize, iconClassName)} />
          )}
          <span>{children}</span>
          {iconPosition === 'right' && (
            <Icon className={cx('ml-2', iconSize, iconClassName)} />
          )}
        </>
      )
    }

    const passedInProps = {
      ...props,
      isNoSpacing,
      isRounded,
      fullWidth,
      size,
      asLabel,
      fontSize,
    }

    return (
      <BaseButton
        ref={ref}
        {...props}
        as={asLabel ? 'span' : props.as}
        className={cx(
          className,
          getButtonStyles(passedInProps),
          getAppearanceButtonStyles(passedInProps),
        )}
      >
        {children}
      </BaseButton>
    )
  },
)
