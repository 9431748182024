import qs from 'query-string'
import { OrderStatus } from 'types/schema'

export const ROUTES = {
  LOGIN: '/login',
  REGISTRY: '/registry',
  PHONE_LOGIN: '/phone-login',
  FORGOT_PASSWORD: '/forgot-password',
  REGISTER_PARTNER: '/register-partner',
  HOME: '/',
  RANK: '/rank',
  ORDERS: (
    params: {
      q?: string
      status?: OrderStatus
      year?: string
      month?: string
    } = {},
  ) => {
    let stringParams = ''
    if (Object.values(params).filter(Boolean).length > 0) {
      stringParams = `?${qs.stringify(params)}`
    }
    return `/order${stringParams}`
  },
  ORDER_CREATE: (
    orderId?: string,
    selectCustomer?: boolean,
    backHref?: string,
  ) => {
    const params = {} as Record<string, any>
    if (orderId) {
      params.orderId = orderId
    }
    if (backHref) {
      params.from = backHref
    }

    if (selectCustomer) {
      params.selectCustomer = true
    }

    let query = qs.stringify(params)
    if (query) {
      query = `?${query}`
    }
    return `/order/new${query}`
  },
  ORDER_CREATE_SUCCESS: (params: { orderId: string; from: string }) => {
    const { from, orderId } = params
    let urlParams = ''
    if (from) {
      urlParams = `?from=${from}`
    }

    return `/order/${orderId}/success${urlParams}`
  },
  ORDER_SELECT_PRODUCTS: (warehouseId: string) => {
    return `/order/new/select-products?warehouseId=${warehouseId}`
  },
  COURSE: '/course',
  COURSE_INFO: '/course/information',
  ORDER_CANCEL: (orderId: string) => {
    return `/order/${orderId}/cancel`
  },
  ORDER_DETAIL: (orderId: string) => {
    return `/order/${orderId}`
  },
  ORDER_EDIT: (orderId: string) => {
    return `/order/${orderId}/edit`
  },
  ORDER_SUPPORT: (orderId: string) => {
    return `/order/${orderId}/support`
  },
  ORDER_PACKAGE_DETAIL: (orderId: string) => {
    return `/order/${orderId}/package-detail`
  },
  ORDER_REVIEW: (orderId: string) => {
    return `/order/${orderId}/review`
  },
  ORDER_REQUEST_EXPORT_VAT_DETAIL: (orderId: string, vatId: string) => {
    return `/order/${orderId}/request-export-vat/${vatId}`
  },
  CUSTOMERS: '/customer',
  NEW_CUSTOMER: '/customer/new',
  CUSTOMER_DETAIL: (id: string) => {
    return `/customer/${id}`
  },
  CUSTOMER_CREATE_KUNGFU: (id: string) => {
    return `/customer/${id}/create-kungfu`
  },
  CUSTOMER_DETAIL_EDIT: (id: string) => {
    return `/customer/${id}/edit`
  },
  CUSTOMER_DETAIL_VALIDATE: (id: string) => {
    return `/customer/${id}/validate`
  },
  CUSTOMER_PURCHASE_HISTORY: (id: string) => {
    return `/customer/${id}/purchase-history`
  },
  CUSTOMER_ORDER: (
    customerId: string,
    params: {
      status?: OrderStatus
      year?: string
      month?: string
    } = {},
  ) => {
    let stringParams = ''
    if (Object.values(params).filter(Boolean).length > 0) {
      stringParams = `?${qs.stringify(params)}`
    }
    return `/customer/${customerId}/order${stringParams}`
  },
  CUSTOMER_NOTES: (customerId: string) => {
    return `/customer/${customerId}/note`
  },
  CUSTOMER_NOTE_NEW: (customerId: string) => {
    return `/customer/${customerId}/note/new`
  },
  CUSTOMER_NOTE_EDIT: (customerId: string, noteId: string) => {
    return `/customer/${customerId}/note/${noteId}/edit`
  },
  CUSTOMER_FORWARD: (customerid: string) => {
    return `/customer/${customerid}/forward`
  },
  CUSTOMER_SCHEDULES: (customerId: string) => {
    return `/customer/${customerId}/schedule`
  },
  CUSTOMER_SCHEDULE_NEW: (customerId: string) => {
    return `/customer/${customerId}/schedule/new`
  },
  CUSTOMER_SCHEDULE_EDIT: (customerId: string, scheduleId: string) => {
    return `/customer/${customerId}/schedule/${scheduleId}/edit`
  },
  ADDRESSES: (customerid: string) => {
    return `/customer/${customerid}/address`
  },
  ADDRESS_EDIT: (customerid: string, addressId: string) => {
    return `/customer/${customerid}/address/${addressId}/edit`
  },
  ADDRESS_NEW: (customerid: string) => {
    return `/customer/${customerid}/address/new`
  },
  CART: '/cart',
  PROFILE: '/profile',
  PROFILE_CHANGE_PASSWORD: '/profile/change-password',
  PROFILE_SUPPORT: '/profile/support',
  PROFILE_COMMISSION: (params?: { year: string; month: string }) => {
    let stringParams = ''
    if (params && Object.values(params).filter(Boolean).length > 0) {
      stringParams = `?${qs.stringify(params)}`
    }
    return `/profile/commission${stringParams}`
  },
  PROFILE_COMMISSION_ORDERS: ({
    month,
    year,
  }: {
    month: number
    year: number
  }) => `commission/${month}/${year}`,
  PROFILE_COMMISSION_COACHING: ({
    month,
    year,
  }: {
    month: number
    year: number
  }) => `commission/coaching/${month}/${year}`,
  PROFILE_COMMISSION_TRAINING: ({
    month,
    year,
  }: {
    month: number
    year: number
  }) => `commission/training/${month}/${year}`,
  PROFILE_DETAIL: '/profile/me',
  PROFILE_DETAIL_EDIT: '/profile/me/edit',
  PROFILE_BANKING: '/profile/banking',
  PROFILE_BANKING_EDIT: '/profile/banking/edit',
  PROFILE_KYC: '/profile/kyc',
  PROFILE_KYC_EDIT: '/profile/kyc/edit',
  PROFILE_HELP: '/profile/help',
  PROFILE_CONTRACT: '/profile/contract',
  PROFILE_GAPO_UPGRADE: '/profile/gapo-upgrade',
  PROFILE_COURSE: '/profile/course',
  PROFILE_VOUCHERS: '/profile/vouchers',
  PROFILE_VOUCHER_DETAIL: (voucherId: string) => {
    return `/profile/vouchers/detail/${voucherId}`
  },
  PRODUCTS: '/product',
  PRODUCTS_BY_TYPE: (type: string) => {
    return type.length > 0 ? `/product?type=${type}` : '/product'
  },
  PRODUCT_DETAIL: (productId: string, productType: number) => {
    return `/product/${productId}?type=${productType}`
  },
  PRODUCT_DETAIL_WITHOUT_TYPE: (productId: string) => {
    return `/product/${productId}`
  },
  PRODUCT_ARTICLE: (productId: string) => {
    return `/product/${productId}/article`
  },
  PRODUCT_ARTICLE_DETAIL: (productId: string, articleId: string) => {
    return `/product/${productId}/article/${articleId}`
  },
  PRODUCT_REVIEWS: (productId: string, productType: number) => {
    return `/product/${productId}/reviews?type=${productType}`
  },
  EDIT_PRODUCT_REVIEW: (productId: string, reviewId: string) => {
    return `/product/${productId}/reviews/${reviewId}/edit`
  },
  PRODUCT_YOUR_REVIEWS: (productId: string, productType: number) => {
    return `/product/${productId}/reviews/your-reviews?type=${productType}`
  },
  MARKETING: '/profile/marketing',
  MARKETING_CENTER_WELCOME: '/profile/marketing-center/welcome',
  MARKETING_CENTER: '/profile/marketing-center',
  MARKETING_CENTER_LEAD_DETAIL: (leadId: string) =>
    `/profile/marketing-center/lead/${leadId}`,
  MARKETING_CENTER_MANAGEMENT: '/profile/marketing-center/management',
  MARKETING_CENTER_HISTORY: (params?: { month: number; year: number }) => {
    if (params) {
      return `/profile/marketing-center/history?${qs.stringify(params)}`
    }

    return '/profile/marketing-center/history'
  },
  MARKETING_CENTER_HISTORY_DETAIL: (id: string) => {
    return `/profile/marketing-center/history/transaction/${id}`
  },
  MARKETING_CENTER_TOP_UP: `/profile/marketing-center/top-up`,
  MARKETING_CENTER_TOP_UP_PAYMENT_DETAIL: (id: string) => {
    return `/profile/marketing-center/top-up/payment-detail?transactionId=${id}`
  },
  MARKETING_CENTER_REGISTER_LEAD: (categoryId: string) =>
    `/profile/marketing-center/register-lead/${categoryId}`,
  MARKETING_PACKAGE_REGISTRY_HISTORY: (categoryId: string) =>
    `/profile/marketing/${categoryId}`,
  MARKETING_PACKAGE_PAYMENT_HISTORY: (categoryId: string) =>
    `/profile/marketing/${categoryId}/payment`,
  REGISTRY_MARKETING: '/profile/marketing/registry',
  PAYMENT_MARKETING: '/profile/marketing/payment',
  LEAD_MARKETING: '/profile/marketing/lead',
  LEAD_DETAIL: (leadId: string) => `/profile/marketing/lead/${leadId}`,
  LEAD_CREATE_KUNGFU: (leadId: string) =>
    `/profile/marketing/lead/${leadId}/create-kungfu`,
  LEAD_NOTE: (leadId: string) => `/profile/marketing/lead/${leadId}/note`,
  NEW_LEAD_NOTE: (leadId: string) =>
    `/profile/marketing/lead/${leadId}/note/new`,
  EDIT_LEAD_NOTE: (leadId: string, nodeId: string) =>
    `/profile/marketing/lead/${leadId}/note/${nodeId}/edit`,
  LEAD_SCHEDULE: (leadId: string) =>
    `/profile/marketing/lead/${leadId}/schedule`,
  NEW_LEAD_SCHEDULE: (leadId: string) =>
    `/profile/marketing/lead/${leadId}/schedule/new`,
  EDIT_LEAD_SCHEDULE: (leadId: string, scheduleId: string) =>
    `/profile/marketing/lead/${leadId}/schedule/${scheduleId}/edit`,
  PROMOTIONS: '/promotion',
  PROMOTION_DETAL: (promotionId: string) => {
    return `/promotion/${promotionId}`
  },
  NOTIFICATION: '/notification',
  NOTIFICATION_DETAIL: (notificationId: string) =>
    `/notification/${notificationId}`,
  PDP_HOME: '/pdp',
  PDP_MALL_DETAIL: (pdpId: string) => {
    return `/pdp/${pdpId}`
  },
  PDP_MALL_DETAIL_INTRO: (pdpId: string) => {
    return `/pdp/${pdpId}/intro`
  },
  DELIVERY_PROVIDER_TET_SCHEDULE: '/tet-holiday',
  ONBOARDING_SURVEY: '/onboarding-survey',
  KUNGFU_ORDER_DETAIL: (orderId: string) => `/order/kungfu/${orderId}`,
  KUNGFU_ORDER_SUCCESS: (orderId: string) => `/order/kungfu/${orderId}/success`,
  KUNGFU_ORDER_INVOICE: (orderId: string) => `/order/kungfu/${orderId}/invoice`,
  KUNGFU_ORDER: '/order/kungfu',
  NEW_KUNGFU_ORDER: '/order/kungfu/new',
  UPGRADE_ACCOUNT: '/upgrade',
  UPGRADE_ACCOUNT_LEARN_MORE: (
    accountType?: string,
    extraQuery?: Record<string, any>,
  ) => {
    if (!accountType && !extraQuery) {
      return '/upgrade/learn-more'
    }
    if (!accountType && extraQuery) {
      return `/upgrade/learn-more?${qs.stringify(extraQuery)}`
    }
    return `/upgrade/learn-more?${qs.stringify({
      accountType,
      ...extraQuery,
    })}`
  },
  UPGRADE_ACCOUNT_TO_NEW_AGENT: '/upgrade/new-agent',
  UPGRADE_ACCOUNT_FROM_TRIAL: '/upgrade/from-trial',
  REFERRAL_LINKS: '/referral-links',
  ACTIVE_SUCCESS: '/active-success',
  UPGRADE_SUCCESS: '/upgrade-success',
  // marketing center
  TOP_UP: `/profile/marketing/top-up`,
  TOP_UP_PAYMENT_DETAIL: `/profile/marketing/top-up/payment-detail`,
  REGISTER_LEAD: `/profile/register-lead`,
}
