import {
  IS_KH_APP,
  IS_PRODUCTION_BUILD,
  IS_US_APP,
  IS_VN_APP,
} from 'constants/dev'

export const flagList = {
  'order.edit_variant': !IS_PRODUCTION_BUILD,
  'order.create_postcard': IS_VN_APP,
  'order.payment_stripe': IS_US_APP,
  'order.shipping_us': IS_US_APP,
  'order.warehouse_map': IS_VN_APP,
  'order.request_export_vat': IS_VN_APP,
  'order.grab_express': IS_VN_APP,
  'order.shipping_voucher': IS_VN_APP,
  'order.voucher_discount_on_order': IS_VN_APP,
  'order.commit_order': IS_VN_APP,
  'order.adp_provide_alert': IS_VN_APP || IS_US_APP,
  'profile.kyc': IS_VN_APP,
  'profile.payment_info': IS_VN_APP || IS_KH_APP,
  'profile.e-contract': IS_VN_APP,
  'profile.app_version': IS_VN_APP,
  'profile.tax_code': IS_VN_APP,
  'profile.gapo_upgrade': IS_VN_APP,
  'profile.adp': IS_US_APP,
  'auth.option_send_otp': IS_US_APP || IS_KH_APP,
  'auth.us_flag': IS_US_APP,
  'auth.register_partner': IS_VN_APP,
  'auth.us_term_and_policy': IS_US_APP,
  'auth.vn_term_and_policy': IS_VN_APP,
  'auth.kh_term_and_policy': IS_KH_APP,
  'feed.zalo_and_telegram_button': IS_VN_APP || IS_KH_APP,
  'feed.tab_button': IS_VN_APP,
  'feed.required_accept_policy': IS_US_APP,
  'marketing.view_package_payment_history': IS_US_APP,
  'marketing.payment_stripe': IS_US_APP,
  'marketing.registry_policies': IS_US_APP,
  'common.support_tel': IS_VN_APP,
  'common.support_mail': IS_US_APP,
  'common.expire_account_no_order': IS_VN_APP,
  'common.kungfu_course_v2': IS_VN_APP,
  'customer.validate_address_on_select': IS_VN_APP,
  'course.payment_stripe': IS_US_APP,
  'course.payment_bank_transfer': IS_VN_APP,
  'course.droppii_care': IS_VN_APP,
  'course.deposit_button': IS_VN_APP,
  'course.become_partner_description': IS_VN_APP,
  'course.us_payment_become_collaborator': false,
  'course.us_payment_description': false,
  'course.us_payment_preferential_deposit': false,
  'course.skip_first_step': IS_US_APP || IS_KH_APP,
  'course.kh_kungfu_flow': IS_KH_APP,
  'course.us_kungfu_flow': IS_US_APP,
  'course.display_active_with_course_id': false,
  'profile.terms_and_condition': IS_VN_APP,
  'profile.commission_retail_commission': !IS_VN_APP,
  'profile.commission_coaching_benefit': !IS_VN_APP,
  'profile.commission_training_benefit': !IS_VN_APP,
  'profile.marketing_qr_payment': !IS_KH_APP,
  'profile.commission_payment_fee': IS_US_APP,
  notification: true,
  'common.dial_code': IS_US_APP || IS_KH_APP,
  'product.sold_detail': IS_VN_APP,
  'product.VAT': IS_VN_APP || IS_KH_APP,
  'product.reviews': true,
  'product.sell_this_product': !IS_PRODUCTION_BUILD,
  'product/pdp/pdpId.filter_brand': !IS_VN_APP, //info: ticket https://droppii.atlassian.net/browse/DROPPII-2853
  'common.display_pdp_label': IS_VN_APP,
  'common.pdp_malls': IS_VN_APP,
  'profile.kungfu_menu': IS_VN_APP,
  'common.hide_on_producton': !IS_PRODUCTION_BUILD,
  'profile.referral_link': IS_US_APP || IS_KH_APP,
  'profile.affiliate_link': IS_US_APP,
  'auth.phone_login': !IS_KH_APP,
} as const
